<template>
  <PageContent
    class="fiche-devis-commande"
    :nav-titles="navTitles"
    :page-title="objectInfos?.libelle"
  >
    <template #header-left>
      <ButtonClassic
        v-if="hasPerm('can_rename_devis')"
        v-tooltip="{
          content: $t('general.renommer'),
          delay: { show: 800, },
        }"
        v-matomo-log-click="['devis_renommer', ]"
        variant="special"
        color="primary"
        icon="right"
        size="small"
        :disabled="isImpersonating"
        @click="showModal('rename')"
      >
        <template #right-icon>
          <UilPen class="icon-small" />
        </template>
      </ButtonClassic>
    </template>
    <template #header-right>
      <ButtonGroup>
        <div
          v-tooltip="{
            content: $t('info.document-pas-disponible'),
            placement: 'bottom',
            delay: { show: 800, },
            disabled: objectInfos?.url_pdf !== null || isLoading,
          }"
        >
          <ButtonClassic
            v-matomo-log-click="['devis_pdf', ]"
            balise-type="a"
            variant="solid"
            icon="right"
            :ext-link="objectInfos?.url_pdf || ''"
            :disabled="!objectInfos?.url_pdf"
          >
            <template #right-icon>
              <IconPDF class="icon-medium" />
            </template>
          </ButtonClassic>
        </div>
        <ButtonClassic
          v-matomo-log-click="['devis_clipboard', ]"
          variant="solid"
          icon="right"
          @click="
            copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)
          "
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="!isLoading && objectInfos"
      #action-bar
    >
      <div class="key-actions">
        <div
          v-if="!hasPerm('can_devis_to_cmd') && objectInfos.budget"
          class="budget flex-vcenter"
        >
          <template v-if="budgetType === 'euros'">
            <UilEuroCircle
              size="16"
              :class="budgetStatus"
            />
            <span class="text-small blue-dark">
              {{ objectInfos.budget.enCours | moneyFilter }}{{ "\xa0" }}€/{{
                objectInfos.budget.dotation | moneyFilter
              }}{{ "\xa0" }}€
            </span>
          </template>
          <template v-else-if="budgetType === 'licences'">
            <UilBooks
              size="16"
              :class="budgetStatus"
            />
            <span class="text-small blue-dark">
              {{ objectInfos.budget.enCours | spaceNumber }}/{{
                objectInfos.budget.dotation | spaceNumber
              }}
            </span>
          </template>
        </div>
        <div
          v-else
          class="flex-vcenter"
        >
          <div
            v-if="objectInfos.nom_organisme"
            class="flex-vcenter organisme-details"
          >
            <IconEstablishment />
            <strong class="text-small name">
              {{ objectInfos.nom_organisme }}
            </strong>
          </div>
          <div
            v-if="
              objectInfos.budget &&
                Object.keys(objectInfos.budget).length &&
                objectInfos.budget.dotation > 0
            "
            class="budget flex-vcenter"
          >
            <template v-if="budgetType === 'euros'">
              <UilEuroCircle
                size="16"
                :class="budgetStatus"
              />
              <span
                v-if="objectInfos.budget"
                class="text-small blue-dark"
              >
                {{ objectInfos.budget.enCours | moneyFilter }}{{ "\xa0" }}€/{{
                  objectInfos.budget.dotation | moneyFilter
                }}{{ "\xa0" }}€
              </span>
            </template>
            <template v-else-if="budgetType === 'licences'">
              <UilBooks
                size="16"
                :class="budgetStatus"
              />
              <span
                v-if="objectInfos.budget"
                class="text-small blue-dark"
              >
                {{ objectInfos.budget.enCours | spaceNumber }}/{{
                  objectInfos.budget.dotation | spaceNumber
                }}
              </span>
            </template>
          </div>
        </div>
        <div
          id="zone_buttons"
          class="flex-vcenter"
        >
          <InputClassic
            v-if="showEngagementInput"
            v-model="engagementInput"
            class="chorus-input-classic"
            type="text"
            :status="engagementRequired ? 'required' : 'none'"
            :required="isImpersonating || engagementRequired"
            inline
            :label="$t('liste.n-engagement')"
          >
            <template #label-icon>
              <IconNumber />
            </template>
          </InputClassic>
          <div class="total-prices">
            <strong
              v-if="objectInfos.total_ttc !== objectInfos.total_ht"
              class="text-medium ttc all-taxes"
              v-html="
                $t('prix.ttc', { prix: moneyFilter(objectInfos.total_ttc), })
              "
            />
            <span
              class="text-medium duty-free"
              v-html="
                $t('prix.ht', { prix: moneyFilter(objectInfos.total_ht), })
              "
            />
          </div>
          <ButtonStatusApprobation
            v-tooltip="tooltipOrderBtn"
            v-matomo-log-click="[
              `devis_primary_btn_haut`,
              'click',
              primaryStatusLabel,
            ]"
            :label="primaryStatusLabel"
            :variant="primaryStatusButton"
            :disabled="
              isImpersonating ||
                statusBtnDisabled ||
                isPrimaryBtnDisabled ||
                objectInfos.verrouille
            "
            @click="handlePrimary()"
          >
            <template #icon>
              <UilProcess v-if="objectInfos.statut_affiche === 'expire'" />
              <UilCheckCircle v-else />
            </template>
          </ButtonStatusApprobation>
          <ButtonStatusApprobation
            v-if="showSecondaryButton"
            v-tooltip="tooltipOrderBtn"
            v-matomo-log-click="[
              `devis_secondary_btn_haut`,
              'click',
              secondaryStatusLabel,
            ]"
            :label="secondaryStatusLabel"
            variant="secondary"
            :disabled="
              isImpersonating ||
                statusBtnDisabled ||
                isSecondaryBtnDisabled ||
                objectInfos.verrouille
            "
            @click="handleSecondary()"
          >
            <template #icon>
              <component :is="buttonSecondaryIcon" />
            </template>
          </ButtonStatusApprobation>
          <ContextMenu
            :bloc-actions-globales="actionsGlobales"
            :bloc-actions-selections="actionsSelections"
            :selections="selection"
            @click-action="handleActions($event)"
          />
        </div>
      </div>
      <div class="actions centered">
        <ButtonClassic
          v-if="actionsGlobales[0].actions.length === 1"
          variant="special"
          :label="actionsGlobales[0].actions[0].label"
          color="primary"
          icon="left"
          :disabled="actionsGlobales[0].actions[0].disabled"
          @click="handleActions(actionsGlobales[0].actions[0])"
        >
          <template #left-icon>
            <component :is="actionsGlobales[0].actions[0].icon" />
          </template>
        </ButtonClassic>
        <ButtonGroup v-else-if="actionsGlobales[0].actions.length > 1">
          <ButtonClassic
            v-for="action in actionsGlobales[0].actions"
            :key="action.slug"
            variant="solid"
            :label="action.label"
            color="white"
            icon="left"
            :disabled="action.disabled"
            @click="handleActions(action)"
          >
            <template
              v-if="action.icon"
              #left-icon
            >
              <component :is="action.icon" />
            </template>
          </ButtonClassic>
        </ButtonGroup>
      </div>
      <p
        v-if="hasNumerique"
        class="text-small italic no-refundable"
      >
        <UisExclamationTriangle />
        {{ $t("licence.licences-numeriques-ni-echangeables-ni-remboursables") }}
      </p>
    </template>

    <template
      v-if="!isLoading"
      #content
    >
      <BlocLignesCatalogues
        :object-infos="objectInfos"
        :catalogues="catalogues"
        :disable-editing="disabledLineEdit"
        :expired="objectInfos.statut_affiche === 'expire'"
        :no-deletion-possible="
          disabledLineEdit || isDevisIDF || objectInfos.lignes.length < 2
        "
        @change-line="!disabledLineEdit ? handleChangeLine($event) : ''"
        @delete-line="!disabledLineEdit ? deleteLine($event) : ''"
        @sort="handleSort($event)"
      />
      <TableTotal
        :object-infos="objectInfos"
        :message-numerique="hasNumerique"
      />
      <div
        v-if="showEngagementInput"
        id="facturation_chorus"
        class="flex-vcenter"
      >
        <p
          v-if="engagementRequired"
          class="flex-vcenter text-small italic"
        >
          {{ $t("chorus.renseignement-obligatoire-facturation-chorus") }}
          <UisExclamationTriangle size="16" />
        </p>
        <InputClassic
          v-model="engagementInput"
          class="chorus-input-classic bottom"
          type="text"
          inline
          :status="engagementRequired ? 'required' : 'none'"
          :required="engagementRequired"
          :label="$t('liste.n-engagement')"
        >
          <template #label-icon>
            <IconNumber />
          </template>
        </InputClassic>
      </div>
      <PiecesJointes
        v-if="canSeeAttachments"
        :attachments="attachments"
        @delete-attachment="deleteAttachment($event)"
      />
      <ListeActions
        context="devis"
        :button-status="primaryStatusButton"
        :button-label="primaryStatusLabel"
        :button-disabled="
          isImpersonating ||
            statusBtnDisabled ||
            isPrimaryBtnDisabled ||
            objectInfos.verrouille
        "
        :approbation-tooltip="tooltipOrderBtn"
        @click-btn-status-approb="handlePrimary()"
        @click-action="handleActions($event)"
      >
        <template #icon>
          <UilProcess v-if="objectInfos.statut_affiche === 'expire'" />
          <UilCheckCircle v-else />
        </template>
        <template
          v-if="showSecondaryButton"
          #additional-buttons
        >
          <ButtonStatusApprobation
            v-tooltip="tooltipOrderBtn"
            v-matomo-log-click="[
              `devis_secondary_btn_bas`,
              'click',
              secondaryStatusLabel,
            ]"
            :label="secondaryStatusLabel"
            variant="secondary"
            large
            :disabled="
              isImpersonating ||
                statusBtnDisabled ||
                isSecondaryBtnDisabled ||
                objectInfos.verrouille
            "
            @click="handleSecondary()"
            @fetch-lignes="fetchDevis()"
          >
            <template #icon>
              <component :is="buttonSecondaryIcon" />
            </template>
          </ButtonStatusApprobation>
        </template>
      </ListeActions>
      <ModalDevisAskReason
        :type="askReasonType"
        @send="handleAskReasonModal($event)"
      >
        <template
          v-if="
            askReasonType === 'modification' &&
              objectInfos.user_to_demande_modification_verbose &&
              objectInfos.user_to_demande_modification_verbose.nom
          "
          #subtitle
        >
          {{
            $t("devis.cette-demande-sera-envoyee-a-x", {
              nom: objectInfos.user_to_demande_modification_verbose.nom,
            })
          }}
        </template>
      </ModalDevisAskReason>
      <ModalDevisReason
        :devis="objectInfos"
        :type="askReasonType"
        @delete-devis="deleteDevis()"
      />

      <ModalDevisCommande
        ref="modal_devis_commande"
        source-type="devis"
        destination-type="commande"
        :data="objectInfos"
        :all-cgv="allCgv"
        :engagement="engagementInput"
        :organisme="objectInfos.organisme"
        :erreur="errorModalDevisCommande"
        @submit="order($event)"
      />
      <ModalSetName
        :libelle="objectInfos.libelle"
        variant="devis"
        rename
        @submit="renameDevis($event)"
      />
      <ModalConfirmDeletion
        :to-delete="selectedDeletedLines"
        variant="devis"
        @confirm="
          handleDeleteLines(selectedDeletedLines.map((l) => l.offre.id))
        "
        @decline="handleDecline()"
      />
      <ModalDevisToListe
        :devis="objectInfos"
        @confirm="toListe($event)"
      />
      <ModalPiecesJointes
        :attachments="attachments"
        :adoptant="isAdoptant"
        @confirm="fetchAttachments('devis')"
      />
      <ModalDevisConfirmDeletion @confirm="deleteDevis($event, true)" />
      <ModalAntares :commande="objectInfos" />
      <ModalDuplicateDevisCommande
        :doublon-infos="doublonInfos"
        @force="forceOrderOrForceDevis"
        @hidden="$refs.modal_devis_commande.hide()"
      />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import { moneyFilter, copyToClipboard, InputClassic } from "@lde/core_lde_vue";

import ModalDevisCommande from "@/components/modals/ModalDevisCommande.vue";
import ModalDevisAskReason from "@/components/modals/ModalDevisAskReason.vue";
import ModalDevisReason from "@/components/modals/ModalDevisReason.vue";
import ModalConfirmDeletion from "@/components/modals/ModalConfirmDeletion.vue";
import ModalDevisToListe from "@/components/modals/ModalDevisToListe.vue";
import ModalSetName from "@/components/modals/ModalSetName.vue";
import ModalAntares from "@/components/modals/ModalAntares.vue";
import ModalDevisConfirmDeletion from "@/components/modals/ModalDevisConfirmDeletion.vue";
import ModalDuplicateDevisCommande from "@/components/modals/ModalDuplicateDevisCommande.vue";

import IconNumber from "@/components/icons/IconNumber.vue";
import IconBookToDevisLeft from "@/components/icons/IconBookToDevisLeft.vue";
import {
  UilTrashAlt,
  UilTimesCircle,
  UilSyncExclamation,
  UilCheckCircle,
  UilProcess,
  UilPen,
} from "@iconscout/vue-unicons";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";
import DevisCommandes from "@/mixins/mixinFicheListeDevisCommandes";
import DuplicateInfos from "@/mixins/mixinDuplicateModal";

import config from "@/config";

import Api from "@/modules/axios";
import { changePageTitle } from "@/modules/utils";

import { mapGetters } from "vuex";

/**
 * Vue d'un devis en particulier avec toutes ses informations.
 */
export default {
  name: "FicheDevis",
  components: {
    InputClassic,
    ModalDevisCommande,
    ModalDevisAskReason,
    ModalDevisReason,
    ModalConfirmDeletion,
    ModalDevisToListe,
    ModalSetName,
    ModalDuplicateDevisCommande,
    IconNumber,
    UilCheckCircle,
    UilProcess,
    UilPen,
    ModalAntares,
    ModalDevisConfirmDeletion,
  },
  mixins: [mixinDisponibilite, DevisCommandes, DuplicateInfos],
  beforeRouteLeave(to, from, next) {
    if (this.modifications && this.modifications.length) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(this.$t("devis.confirmation-quitter-page"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      actions: [], // Actions en bas de page
      actionsGlobales: [
        // Actions globales du menu
        {
          title: this.$t("context-menu.actions-applicables-au-devis"),
          actions: [],
        },
      ],
      actionsSelections: [], // Actions de sélection du menu
      modifications: [], // Modification des lignes du devis
      statusBtnDisabled: false, // État du bouton de status
      selectedDeletedLines: [], // Ligne en attente de suppression (pour la modale de confirmation)
      isSelfEditing: false, // Utilisé pour le mode "édition" pour la région et valideur
      askReasonType: "modification", // Type de la modale de demande de raison
      engagementInput: "",
      controller: new AbortController(),
      doublonInfos: {
        sameCommandeInfo: {},
        duplicateDevisInfos: {},
        duplicateCommandeInfos: {},
      },
      saveDevisBody: null,
      saveOrderBody: null,
      saveToast: null,
      errorModalDevisCommande: false,
    };
  },
  computed: {
    ...mapGetters(["isIDF"]),
    selection() {
      return (
        this.objectInfos?.lignes
        && this.objectInfos.lignes.filter((l) => l._checked)
      );
    },
    tooltipOrderBtn() {
      return this.isCommandeClosed
        ? { content: this.$t("commande.les-commandes-sont-fermees") }
        : {};
    },
    isCurrentUserAskedToModif() {
      return (
        this.objectInfos.modifiable
        && this.objectInfos.date_demande_modification
        && this.user.id === this.objectInfos.user_to_demande_modification
      );
    },
    disabledLineEdit() {
      if (
        (this.objectInfos.modifiable
          && this.isSelfEditing
          && this.objectInfos.statut_affiche !== "expire")
        || this.objectInfos.statut_affiche === "attente_selection"
        || this.isImpersonating
      ) {
        return false;
      }

      return true;
    },
    isDevisIDF() {
      return (
        this.isIDF
        && this.objectInfos.libelle.includes("R3A")
        && this.hasPerms(["can_devis_to_cmd", "can_view_as_maitre_compta"])
      );
    },
    devisDepasseDotation() {
      let text = `${this.$t("devis.depasse-la-dotation")}`;
      if (this.objectInfos.budget) {
        text += ` (${this.$t("dotation.dotation-restante")}&nbsp;:
                ${this.objectInfos.budget.solde} ${this.budgetType})`;
      }
      return text;
    },
    canSeeAttachments() {
      const etatsEM = ["attente_approbation", "attente_commande", "refuse"];
      return (
        !this.isHorsMarche && etatsEM.includes(this.objectInfos.statut_affiche)
      );
    },
    engagementRequired() {
      return (
        this.organismeActuel.infos.chorus_configuration.chorus
        && (this.organismeActuel.infos.chorus_configuration.need_engagement
          || this.organismeActuel.infos.chorus_configuration
            .need_service_or_engagement)
      );
    },
    showEngagementInput() {
      return (
        this.isAllowed("commander")
        && this.organismeActuel.infos.chorus_configuration.chorus
      );
    },
    showSecondaryButton() {
      return this.secondaryStatusLabel !== null;
    },
    isSecondaryBtnDisabled() {
      return this.isCommandeClosed;
    },
  },
  watch: {
    selection(newVal) {
      if (this.isSelfEditing && newVal && this.objectInfos.lignes.length > 1) {
        this.actionsSelections = [
          {
            slug: "delete_lines",
            label: this.$t("action.tout-supprimer"),
            icon: UilTrashAlt,
            disabled:
              newVal.length === this.objectInfos?.lignes.length
              || this.objectInfos.verrouille,
          },
        ];
      }
    },
  },
  mounted() {
    this.fetchAttachments("devis").catch(({ message }) => {
      this.$toast.error({ title: message });
    });

    this.fetchDevis().then(() => {
      this.isLoading = false;
      if (this.objectInfos.statut_affiche !== "expire") {
        if (this.isCurrentUserAskedToModif) {
          this.isSelfEditing = true;
          this.actions.push({
            slug: "to_liste",
            label: this.$t("action.repasser-en-liste"),
            icon: IconBookToDevisLeft,
            disabled: this.isImpersonating || this.objectInfos.verrouille,
          });
          if (!this.isDevisIDF) {
            this.actionsGlobales[0].actions.push(...this.actions, {
              slug: "handle_primary",
              label: this.$t("action.enregistrer-et-renvoyer"),
              icon: UilCheckCircle,
              disabled: this.isImpersonating || this.objectInfos.verrouille,
            });
            if (this.objectInfos.lignes.length > 1) {
              this.actionsSelections.push({
                slug: "delete_lines",
                label: this.$t("action.tout-supprimer"),
                icon: UilTrashAlt,
                disabled: this.isImpersonating || this.objectInfos.verrouille,
              });
            }
          }
          this.$nextTick(() => {
            this.showModal("reason");
          });
        } else if (
          this.objectInfos.statut_affiche === "refus"
          && this.objectInfos.se_raison_refus
          && this.hasPerms(["can_liste_to_devis", "can_transmettre_devis"])
        ) {
          this.askReasonType = "rejet";
          this.$nextTick(() => {
            this.showModal("reason");
          });
        }
      }
      this.setupActions();
    });

    window.onbeforeunload = () => this.modifications?.length > 0 || null;
  },
  beforeDestroy() {
    window.onbeforeunload = null;
    this.controller.abort();
  },
  methods: {
    moneyFilter,
    copyToClipboard,
    /**
     * Récupère les informations du devis.
     * @returns {Promise} Réponse après chargement du devis.
     */
    fetchDevis() {
      this.isLoading = true;
      this.statusBtnDisabled = true;

      return Api()
        .get(`/devis/${this.$route.params.id}/`, {
          signal: this.controller.signal,
        })
        .then(({ data }) => {
          if (this.controller.signal.aborted) {
            return false;
          }

          this.objectInfos = data;

          const lignes = [];
          this.objectInfos.lignes.forEach((ligne) => {
            lignes.push(ligne);

            if (!this.objectInfos.demandes_licences) {
              return;
            }

            const demande = this.objectInfos.demandes_licences.find(
              (dmd) => ligne.offre.manuel_numerique
                && ligne.offre.manuel_numerique.ean === dmd.reference,
            );

            if (demande) {
              lignes.push({
                libelle: demande.titre,
                ean: demande.ref_gratuite,
                reference_interdacta: "zzz",
                quantite: demande.nb_ens_necessaire - demande.gratuite_ens,
                total_ligne_ttc: 0,
                total_ligne_ht: 0,
                raison: demande.raison,
                gratuites: demande.gratuite_ens,
                prix_unitaire_ht: 0,
                prix_unitaire_ttc: 0,
                offre: {
                  id: demande.ref_gratuite,
                  manuel_numerique: {
                    libelle: "Licence enseignant",
                    id: ligne.offre.manuel_numerique.id,
                    url_couverture: ligne.offre.manuel_numerique.url_couverture,
                    ean: demande.ref_gratuite,
                  },
                  duree_verbose: ligne.offre.duree_verbose,
                  code_disponibilite: ligne.offre.code_disponibilite,
                },
                _force_additional: true,
              });
            }
          });
          this.$set(this.objectInfos, "lignes", lignes);

          this.fetchCgvUrl("devis");
          const id4d = this.objectInfos.id_4d || this.$t("info.id-inconnu");
          const date = this.$moment(this.objectInfos.date).format(this.$t("global.format-date"));
          changePageTitle(`${this.objectInfos.libelle} - ${id4d} - ${date}`, this.$route);

          if (this.isDevisIDF) {
            // Blocage de suppression via input à 0
            this.objectInfos.lignes.forEach((ligne) => {
              ligne.quantite_min = 1;
            });
          }

          // Si on est région ou valideur, on récupère les données de l'organisme
          if (this.hasPerm("can_transmettre_devis")) {
            this.$store
              .dispatch("fetchBudgetOrganisme", this.objectInfos.organisme.id)
              .then((resBudget) => {
                this.budgetType = resBudget.type === "QUANTITE" ? "licences" : "euros";
                this.objectInfos = {
                  ...this.objectInfos,
                  nom_organisme: resBudget.nom,
                  budget: resBudget,
                };
                this.statusBtnDisabled = false;
                this.isLoading = false;
              })
              .catch((error) => {
                this.$toast.error({ title: error });
              });
          } else {
            this.statusBtnDisabled = false;
            this.isLoading = false;
          }

          return true;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 404) {
            this.$store.commit("setErrorStatus", err.response.status);
          }
          throw err;
        });
    },
    /**
     * Met en place le menu contextuel et la barre d'actions en bas de page.
     */
    setupActions() {
      this.actions = [];
      this.actionsGlobales[0].actions = [];
      this.actionsSelections = [];

      if (this.objectInfos.statut_affiche === "expire") {
        this.actionsGlobales[0].actions = [
          {
            slug: "refresh",
            label: this.$t("action.actualiser-et-renouveler-le-devis"),
            icon: UilProcess,
            disabled: this.isImpersonating || this.objectInfos.verrouille,
          },
        ];
      }

      if (this.isAllowed("to_liste")) {
        this.actions.push({
          slug: "to_liste",
          label: this.$t("action.repasser-en-liste"),
          icon: IconBookToDevisLeft,
          disabled: this.isImpersonating || this.objectInfos.verrouille,
        });
      }

      if (this.objectInfos.modifiable && this.isAllowed("modif")) {
        this.isSelfEditing = false;
        this.actions.push({
          slug: "edit",
          label: this.$t("action.modifier-moi-meme"),
          icon: UilSyncExclamation,
          disabled: this.isImpersonating || this.objectInfos.verrouille,
        });
      }

      if (this.objectInfos.modifiable && this.isAllowed("dmd_modif")) {
        this.actions.push({
          slug: "ask_modification",
          label: this.$t("action.demander-une-modification"),
          icon: UilSyncExclamation,
          disabled: this.isImpersonating || this.objectInfos.verrouille,
        });
      }

      if (this.isAllowed("rejet")) {
        this.actions.push({
          slug: "reject",
          label: this.$t("action.rejeter"),
          icon: UilTimesCircle,
          disabled: this.isImpersonating || this.objectInfos.verrouille,
        });
      }

      if (this.isAllowed("suppr")) {
        this.actions.push({
          slug: "delete",
          label: this.$t("action.supprimer"),
          icon: UilTrashAlt,
          disabled: this.isImpersonating || this.objectInfos.verrouille,
        });
      }

      this.actionsGlobales[0].actions = [...this.actions];
    },
    /**
     * Retire les actions lorsqu'on passe en attente de modification.
     * @param {Object} text contient le texte de raison de la modification/rejet.
     */
    handleAskReasonModal(text) {
      if (text) {
        const data = {};
        const toast = {};
        const { id, libelle } = this.objectInfos;
        this.isLoading = true;

        if (this.askReasonType === "modification") {
          data.raison_demande_modification = text;

          Api()
            .post(`/devis/${id}/ask_update/`, data)
            .then(() => {
              this.fetchDevis().then(() => {
                this.setupActions();
                this.isLoading = false;
              });
              this.$toast.success({
                title: this.$t("devis.demande-modification-transmise"),
              });
            })
            .catch(({ response }) => {
              this.$toast.error({ title: response.data });
            })
            .finally(() => {
              this.$modal.hide("modal_devis_ask_reason");
            });
        } else {
          data.se_etat = "REFUSE";
          data.se_raison_refus = text;
          toast.title = this.$t("devis.le-devis-bien-rejete");
          toast.content = this.isHorsMarche
            ? this.$t("devis.createur-devis-sera-notifie-rejet-de-x", {
              libelle,
            })
            : this.$t("devis.etablissement-sera-notifie-du-rejet-x", {
              libelle,
            });
          this.patchDevis(data, toast);
        }
      }
    },
    /**
     * Gestion lorsqu'on décline la suppression de ligne.
     */
    handleDecline() {
      const copy = [...this.objectInfos.lignes];
      this.selectedDeletedLines.forEach((line) => {
        if (line.quantite <= 0) {
          const ligne = copy.find((l) => l.id === line.id);
          if (ligne) {
            ligne.quantite = 1;
            if (ligne.quantite < ligne.offre.quantite_min) {
              this.statusBtnDisabled = true;
            }
          }
        }
      });
      this.objectInfos.lignes = copy;
      this.recalculateTotal();
      this.selectedDeletedLines = [];
    },
    /**
     * Met à jour un devis lorqu'il change (ex : modification de la quantité).
     * @param {Object} line Ligne sélectionnée.
     */
    handleChangeLine(line) {
      const obj = { id_offre: line.offre.id, quantite: line.quantite };
      const lignesNum = this.objectInfos.lignes.filter(
        (ligne) => ligne.numerique,
      );

      this.recalculateTotal();

      // On désactive le bouton si le devis appartient à l'IDF
      // OU si au moins une ligne numérique possède une quantité inférieure à la minimum sans avoir coché de raison
      if (
        this.isDevisIDF
        || lignesNum.some(
          (ligne) => ligne.quantite < ligne.offre?.quantite_min && !ligne.raison_minima,
        )
      ) {
        this.statusBtnDisabled = true;
      } else if (
        lignesNum.some(
          (ligne) => ligne.quantite < ligne.offre?.quantite_min && ligne.raison_minima,
        )
      ) {
        this.statusBtnDisabled = false;
        if (line.numerique) {
          obj.raison_minima = line.raison_minima;
        }
      } else {
        this.statusBtnDisabled = false;
      }

      if (!this.statusBtnDisabled) {
        if (!line.quantite) {
          this.selectedDeletedLines = [line];
        } else {
          // Récupère la position de l'élément dans modifications
          const elementPos = this.modifications
            .map((x) => x.id_offre)
            .indexOf(line.offre.id);
          if (elementPos !== -1) {
            this.modifications[elementPos] = obj;
          } else {
            this.modifications.push(obj);
          }
        }
      }
    },
    /**
     * Recalcule les totaux.
     */
    recalculateTotal() {
      this.objectInfos.total_ttc = 0;
      this.objectInfos.total_ht = 0;
      this.objectInfos.lignes.forEach((ligne) => {
        if (
          ligne.quantite
          && (ligne.prix_unitaire_ttc || ligne.prix_unitaire_ht)
        ) {
          this.objectInfos.total_ttc
            += Math.round(
              parseFloat(ligne.prix_unitaire_ttc) * ligne.quantite * 100,
            ) / 100;
          this.objectInfos.total_ht
            += Math.round(
              parseFloat(ligne.prix_unitaire_ht) * ligne.quantite * 100,
            ) / 100;
        }
      });
    },
    /**
     * Supprime une ligne des devis.
     * @param {Object} line Ligne sélectionnée.
     */
    deleteLine(line) {
      this.selectedDeletedLines = [line];
      this.showModal("confirm_deletion");
    },
    /**
     * Affiche la modale selon la clé.
     */
    showModal(key) {
      let idModal;
      switch (key) {
        case "confirm_deletion":
          // Affiche la modale de confirmation de suppression.
          idModal = "modal_confirm_deletion";
          break;
        case "reason":
          // Affiche la modale détaillant la raison d'une demande de modification ou d'un rejet du devis.
          idModal = "modal_devis_reason";
          break;
        case "ask_reason_modification":
          // Affiche la modale de demande de modification.
          this.askReasonType = "modification";
          idModal = "modal_devis_ask_reason";
          break;
        case "ask_reason_rejet":
          // Affiche la modale de rejet.
          this.askReasonType = "rejet";
          idModal = "modal_devis_ask_reason";
          break;
        case "to_liste":
          // Affiche la modale de renommage de liste.
          idModal = "modal_devis_to_liste";
          break;
        case "attachment":
          // Affiche la modale d'ajout de PJ.
          idModal = "modal_pieces_jointes";
          break;
        case "order":
          // Affiche la modale de passage en commande.
          idModal = "modal_devis_commande";
          break;
        case "rename":
          // Affiche la modale de renommage.
          idModal = "modal_set_name";
          break;
        default:
          break;
      }
      if (idModal) {
        this.$modal.show(idModal);
      } else {
        console.error(`showModal: ID de modale ${key} pas trouvé.`);
      }
    },

    /**
     * Affiche la modale de passage en commande.
     */
    handleFirstModalToOpen() {
      if (this.organismeActuel.id_organisme.toLowerCase() === "24c0100") {
        return this.$modal.show("modal_antares");
      }

      this.showModal("order");
      return true;
    },
    /**
     * Vide le devis selon les ids.
     * @param {Array} ligneOffersIds Ids des lignes qu'on souhaite supprimer.
     * @param {String} successMsg Message de succès dans le toast.
     */
    handleDeleteLines(ligneOffersIds) {
      const copyLines = [...this.objectInfos.lignes];
      this.recalculateTotal();
      ligneOffersIds.forEach((offreId) => {
        const obj = { id_offre: offreId, quantite: 0 };
        const ligneCouranteIndex = copyLines.findIndex(
          (ligne) => ligne.offre.id === offreId,
        );
        if (ligneCouranteIndex !== -1) {
          copyLines.splice(ligneCouranteIndex, 1);
          // Index du tableau de modifications de la ligne courante.
          const modifIndex = this.modifications.findIndex(
            (ligne) => ligne.id_offre === offreId,
          );
          if (modifIndex !== -1) {
            this.modifications[modifIndex] = obj;
          } else {
            this.modifications.push(obj);
          }
        }
      });

      this.objectInfos.lignes = copyLines;

      if (!copyLines.length) {
        this.statusBtnDisabled = true;
      }
      if (copyLines.length === 1) {
        this.$set(this.objectInfos.lignes[0], "_checked", false);
        this.actionsSelections.shift();
      }

      this.$toast.success({
        title: this.$tc(
          "devis.ligne-bien-supprimee",
          this.selectedDeletedLines.length,
        ),
      });
      this.selectedDeletedLines = [];
    },
    /**
     * Gère les actions du bouton principal via leur slug.
     * @param {Object} action Action cliquée.
     */
    handleActions(action) {
      switch (action.slug) {
        case "reject":
          this.showModal("ask_reason_rejet");
          break;
        case "delete":
          this.deleteDevis();
          break;
        case "edit":
          this.isSelfEditing = true;
          this.actions = [];
          this.actionsGlobales[0].actions = [
            {
              slug: "handle_primary",
              label: this.$t("action.enregistrer"),
              icon: UilCheckCircle,
            },
          ];
          if (this.objectInfos.lignes.length > 1) {
            this.actionsSelections = [
              {
                slug: "delete_lines",
                label: this.$t("action.tout-supprimer"),
                icon: UilTrashAlt,
                disabled: true,
              },
            ];
          }
          this.$toast.success({
            title: this.$t("devis.vous-pouvez-modifier-le-devis"),
          });
          break;
        case "ask_modification":
          this.showModal("ask_reason_modification");
          break;
        case "delete_lines":
          this.selectedDeletedLines = this.selection;
          this.showModal("confirm_deletion");
          break;
        case "handle_primary":
          this.handlePrimary();
          break;
        case "handle_secondary":
          this.handleSecondary();
          break;
        case "order":
          this.handleFirstModalToOpen();
          break;
        case "to_liste":
          this.showModal("to_liste");
          break;
        case "refresh":
          this.refreshDevis();
          break;
        case "rename":
          this.showModal("rename");
          break;
        default:
          break;
      }
    },
    /**
     * Passe le devis en commande.
     * @param {Object} body pour envoyer à l'api lors du passage en commande.
     */
    order(body = {}) {
      this.$toast.info({
        title: this.$t("toast.envoi-en-cours"),
        content: this.$t("toast.veuillez-patienter"),
      });

      this.saveOrderBody = body;

      const id = this.objectInfos.id || this.$route.params.id;
      this.statusBtnDisabled = true;

      Api()
        .post(`/devis/${id}/to_commande/`, body)
        .then(({ data }) => {
          this.$toast.success({
            title: this.$tc("commande.commande-validee", 1),
            content: this.$t("devis.devis-bien-passe-en-commande"),
          });

          // Redirection vers commande
          this.$router.push({
            name: "commandes_factures_commandes_item",
            params: { id: data.id },
          });
        })
        .catch((err) => {
          this.isLoading = false;
          this.statusBtnDisabled = false;
          if (err.response) {
            if (!this.handleDuplicateModalToOpen(err.response.data)) {
              this.$toast.error({
                title:
                  err.response.data || this.$t("info.une-erreur-est-survenue"),
              });
              this.errorModalDevisCommande = true;
            }
          }
        });
    },
    /**
     * Passe le devis en liste.
     * @param {String} libelle Nom de la liste.
     */
    toListe(libelle = "") {
      this.$toast.info({
        title: this.$t("devis.passage-devis-liste-en-cours"),
        content: this.$t("toast.veuillez-patienter"),
      });

      Api()
        .post(`/devis/${this.objectInfos.id}/to_liste/`, { libelle })
        .then(({ data }) => {
          this.$toast.success({
            title: this.$t("devis.devis-ete-repasse-en-liste"),
          });
          this.$store.dispatch("defineCurrentListId", data.id);

          // Redirection vers la liste
          this.$router.push({
            name: "listes_devis_listes_item",
            params: { id: data.id },
          });
        })
        .catch((err) => {
          this.$toast.error({
            title:
              err.response?.data || this.$t("info.une-erreur-est-survenue"),
          });
        });
    },
    /**
     * Appelé lors du clic sur le bouton d'action en haut (celui de gauche si deux boutons).
     */
    async handlePrimary() {
      let seEtat = null;

      const toast = {
        title: "",
        content: "",
      };

      if (this.objectInfos.statut_affiche === "expire") {
        this.refreshDevis();
        return;
      }

      // Si on est en mode modification (moi-même ou demande)
      if (this.isSelfEditing) {
        this.handleSaveAndResend();
        this.setupActions();
        this.isSelfEditing = false;
      } else {
        switch (this.objectInfos.statut_affiche) {
          case "attente_validation":
            seEtat = this.isHorsMarche
              ? config.statutsCommande.VALIDE
              : config.statutsCommande.VALIDE_DIRECTEUR;
            toast.title = this.$t("devis.devis-bien-ete-valide");
            break;
          case "attente_approbation":
            seEtat = config.statutsCommande.VALIDE;
            toast.title = this.$t("devis.devis-bien-ete-approuve");
            toast.content = this.$t("devis.devis-x-en-attente-de-commande", {
              libelle: this.objectInfos.libelle,
            });
            break;
          default:
            break;
        }

        if (seEtat) {
          this.patchDevis({ se_etat: seEtat }, toast);
        }
      }
    },
    /**
     * Gestion lors du clic sur le second bouton d'action en haut (celui de droite le cas échéant).
     */
    handleSecondary() {
      let seEtat = null;
      const toast = {
        title: "",
        content: "",
      };

      switch (this.objectInfos.statut_affiche) {
        case "attente_validation":
          if (this.isHorsMarche) {
            this.handleFirstModalToOpen();
          } else {
            seEtat = config.statutsCommande.TRANSMIS;
            toast.title = this.$t(
              "devis.devis-bien-ete-soumis-a-la-collectivite",
            );
            toast.content = this.$t(
              "devis.une-notification-de-soumission-sera-envoyee-a-la-collectivite",
            );
          }
          break;
        case "refus":
        case "attente_soumission":
          seEtat = config.statutsCommande.TRANSMIS;
          toast.title = this.$t(
            "devis.devis-bien-ete-soumis-a-la-collectivite",
          );
          toast.content = this.$t(
            "devis.une-notification-de-soumission-sera-envoyee-a-la-collectivite",
          );
          break;
        case "attente_approbation":
        case "attente_commande":
          this.handleFirstModalToOpen();
          break;
        default:
          break;
      }

      if (seEtat) {
        this.patchDevis({ se_etat: seEtat }, toast);
      }
    },
    /**
     * Enregistre toutes les modifications du devis.
     * @returns {Promise}
     */
    handleSaveAndResend() {
      this.statusBtnDisabled = true;
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        Api()
          .put(`/devis/${this.objectInfos.id}/`, { lignes: this.modifications })
          .then(() => {
            this.fetchDevis().then(() => {
              this.setupActions();
              this.isLoading = false;
            });
            this.isSelfEditing = false;
            if (this.modifications?.length) {
              const toast = { title: this.$t("toast.devis-bien-modifie") };
              if (this.objectInfos.user_created === this.user.id) {
                toast.title = this.$t(
                  "liste.liste-soumise-aux-responsables-commandes",
                );
              } else if (
                this.objectInfos.user_to_demande_modification_verbose?.role
                !== "valideur"
              ) {
                toast.content = this.$t(
                  "toast.createur-devis-notifie-modification",
                );
              }
              this.$toast.success(toast);
              this.modifications = [];
            }
            resolve();
          })
          .catch((error) => {
            this.$toast.error({ title: error });
            this.statusBtnDisabled = false;
            reject(error);
          });
      });
    },
    /**
     * Supprime le devis.
     */
    deleteDevis(confirm) {
      if (!confirm) {
        return this.$modal.show("modal_devis_confirm_deletion");
      }

      return this.patchDevis(
        { date_archivage: new Date() },
        {
          title: this.$t("devis.devis-x-bien-supprime", {
            libelle: this.objectInfos.libelle,
          }),
          content: this.$t("devis.createur-devis-notifie-de-suppression"),
        },
      );
    },

    /**
     * Fait un appel à l'api pour modifier l'état du devis.
     * @param {Object} body Données à modifier.
     * @param {Object} toast Données du toast.
     */
    patchDevis(body, toast) {
      this.isLoading = true;
      this.statusBtnDisabled = true;

      this.saveDevisBody = body;
      this.saveToast = toast;

      Api()
        .patch(`/devis/${this.objectInfos.id}/`, body)
        .then(() => {
          this.fetchDevis()
            .then(() => {
              this.setupActions();
              this.isLoading = false;
            })
            .catch((err) => {
              if (err.response.status === 404) {
                const routeName = this.hasPerm("can_view_as_maitre_compta")
                  ? "listes_devis_devis_soumis"
                  : "listes_devis_devis";

                this.$router.push({ name: routeName });
              }
            });
          this.$toast.success(toast);
        })
        .catch((err) => {
          if (err.response) {
            const { status, data } = err.response;
            // Une 404 mais c'est normal, car le devis devient inaccessible
            if (status === 404) {
              this.$toast.success(toast);

              const routeName = this.hasPerm("can_view_as_maitre_compta")
                ? "listes_devis_devis_soumis"
                : "listes_devis_devis";
              this.$router.push({ name: routeName });
            } else if (status === 400 && data.code === "DOTATION_DEPASSEE") {
              this.$toast.error({
                title: this.$t("toast.transmission-impossible"),
                content: data.message,
              });
            } else {
              this.isLoading = false;
              if (!this.handleDuplicateModalToOpen(err.response.data)) {
                this.$toast.error({
                  title:
                    data.message
                    || data.detail
                    || this.$t("info.une-erreur-est-survenue"),
                });
              }
            }
          } else {
            this.$toast.error({
              title: this.$t("info.une-erreur-est-survenue"),
            });
          }
        })
        .finally(() => {
          this.statusBtnDisabled = false;
        });
    },
    /**
     * Renouvelle le devis.
     */
    refreshDevis() {
      Api().post(`devis/${this.$route.params.id}/refresh/`)
        .then(({ data: { data, message } }) => {
          this.$router.push({
            name: "listes_devis_devis_item",
            params: { id: data.id },
          });
          if (message) {
            this.$toast.warning({ title: message });
          } else {
            this.$toast.success({ title: this.$t("devis.votre-devis-a-bien-ete-renouvele") });
          }
        })
        .catch((err) => {
          this.$toast.error({ title: err.response?.data || err.message });
        });
    },
    /**
     * Renomme le devis.
     * @param {String} libelle Nouveau nom du devis.
     */
    renameDevis(libelle) {
      this.patchDevis(
        { libelle },
        { title: this.$t("devis.votre-devis-bien-renomme") },
      );
    },

    forceOrderOrForceDevis(justification) {
      if (this.saveOrderBody) {
        const body = this.saveOrderBody;
        body.justification = justification;
        body.force = true;
        this.order(body);
      } else if (this.saveDevisBody) {
        const body = this.saveDevisBody;
        body.justification = justification;
        if (body && this.saveToast) {
          body.force = true;
          this.patchDevis(body, this.saveToast);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_fiche_devis_commande.scss";
</style>
